const mode = process.env.REACT_APP_MODE;

const servers = {
  local: 'http://localhost:3000',
  local3003: 'http://localhost:3003',
  development: 'http://193.110.3.88:3000',
  csc: 'http://csc.onpoint.ru',
  stada: 'https://api.stada.onpoint.ru:3000'
  // test: 'http://localhost:3000',
  // test: 'http://10.100.10.164:3000',
  // development: 'http://193.110.3.88:3000',
};
const SERVER_URL = servers[mode];
// const STATISTIC_SENDER_URL = 'http://localhost:8082';
const STATISTIC_SENDER_URL = SERVER_URL.replace(':3000', ':8082');
const NEW_STATISTIC = mode === 'petrovax';
if (SERVER_URL === undefined) throw new Error('SERVER_URL is undefined');

export { SERVER_URL, NEW_STATISTIC, STATISTIC_SENDER_URL };
